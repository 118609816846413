export const openNotif = () => {
    return {
        type:'OPEN_NOTIF'
    }
}

export const closeNotif = () => {
    return {
        type:'CLOSE_NOTIF'
    }
}